@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Serif:wght@300;400;700&family=Work+Sans:wght@400;500;700&display=swap');

#site-root * {
  -ms-overflow-style: none;
  scrollbar-width: none;
  box-sizing: border-box;
  --site-font-work: 'Work Sans', sans-serif;
  --site-font-ibm: 'IBM Plex Serif', serif;
  --site-primary: #7909ea;
  --site-primary-dark: #6306c0;
  --site-orange: #ff7654;
  --site-orange-dark: #fa6400;
  --site-blue: #00c1d5;
  --site-bg-white: #f8f8f9;
  --site-text-gray: #616161;
  font-family: var(--site-font-work);
}

::-webkit-scrollbar {
  display: none;
}

#site-root {
  margin: 0;
  padding: 0;
  background-color: #f8f8f9;

  a {
    text-decoration: none;
    color: inherit;

    &.get-started-btn {
      color: var(--site-primary-dark);
      margin: 0;
    }
  }

  button {
    border: none;
    outline: none;
    cursor: pointer;
    background: transparent;
    padding: 0;
    border-radius: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  img {
    object-fit: cover;
    object-position: center;
  }
}

@media screen and (max-width: 425px) {
  #site-root {
    .action h1 {
      font-size: 21px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.14px;
      text-align: center;
      color: #000000;
    }
    section.card-section {
      width: auto;
      margin: 5em 7vw;
    }
  }
}
