@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Serif:wght@300;400;700&family=Work+Sans:wght@400;500;700&display=swap);
.carousel {
  --tml-padding: 40px;
  position: relative;
  display: flex; }
  @media screen and (min-width: 1000px) {
    .carousel {
      --tml-padding: calc((100vw - 700px) / 2); } }
  .carousel > button.carousel {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: var(--tml-padding);
    height: 100%;
    background: var(--site-bg-white) !important;
    opacity: 0.5;
    display: flex;
    align-items: center;
    justify-content: flex-end !important; }
    .carousel > button.carousel.right {
      left: unset;
      right: 0;
      justify-content: flex-start !important; }
    .carousel > button.carousel > img {
      width: 40px; }
  .carousel > section.carousel {
    width: 100vw;
    overflow: scroll;
    padding: 0;
    margin: 0; }
    .carousel > section.carousel > span {
      display: inline-flex; }
      .carousel > section.carousel > span > .testimonial {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: calc(100vw - (2 * var(--tml-padding)));
        padding: var(--tml-padding) 0;
        margin: 0 var(--tml-padding);
        border: unset;
        background-color: unset; }
        .carousel > section.carousel > span > .testimonial:first-child {
          margin-left: var(--tml-padding); }
        .carousel > section.carousel > span > .testimonial:last-child {
          margin-right: var(--tml-padding); }
        @media screen and (min-width: 1000px) {
          .carousel > section.carousel > span > .testimonial {
            width: 700px;
            padding: 50px 100px;
            margin: 0; } }
        .carousel > section.carousel > span > .testimonial > h3 {
          font-family: var(--site-font-ibm) !important;
          font-weight: 300;
          text-align: center; }
        .carousel > section.carousel > span > .testimonial > h5 {
          font-size: 17px;
          font-weight: bold;
          margin: 5px 0; }
        .carousel > section.carousel > span > .testimonial > p {
          font-size: 15px;
          color: #949494;
          margin: 5px 0; }
        .carousel > section.carousel > span > .testimonial > img {
          height: 72px;
          width: 72px; }

#site-root * {
  -ms-overflow-style: none;
  scrollbar-width: none;
  box-sizing: border-box;
  --site-font-work: 'Work Sans', sans-serif;
  --site-font-ibm: 'IBM Plex Serif', serif;
  --site-primary: #7909ea;
  --site-primary-dark: #6306c0;
  --site-orange: #ff7654;
  --site-orange-dark: #fa6400;
  --site-blue: #00c1d5;
  --site-bg-white: #f8f8f9;
  --site-text-gray: #616161;
  font-family: var(--site-font-work); }

::-webkit-scrollbar {
  display: none; }

#site-root {
  margin: 0;
  padding: 0;
  background-color: #f8f8f9; }
  #site-root a {
    text-decoration: none;
    color: inherit; }
    #site-root a.get-started-btn {
      color: var(--site-primary-dark);
      margin: 0; }
  #site-root button {
    border: none;
    outline: none;
    cursor: pointer;
    background: transparent;
    padding: 0;
    border-radius: 0;
    display: flex;
    justify-content: center;
    align-items: center; }
  #site-root img {
    object-fit: cover;
    object-position: center; }

@media screen and (max-width: 425px) {
  #site-root .action h1 {
    font-size: 21px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.14px;
    text-align: center;
    color: #000000; }
  #site-root section.card-section {
    width: auto;
    margin: 5em 7vw; } }

