.carousel {
  --tml-padding: 40px;

  position: relative;
  display: flex;

  @media screen and (min-width: 1000px) {
    --tml-padding: calc((100vw - 700px) / 2);
  }

  & > button.carousel {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;

    width: var(--tml-padding);
    height: 100%;

    background: var(--site-bg-white) !important;
    opacity: 0.5;

    display: flex;
    align-items: center;
    justify-content: flex-end !important;

    &.right {
      left: unset;
      right: 0;
      justify-content: flex-start !important;
    }

    & > img {
      width: 40px;
    }
  }

  & > section.carousel {
    width: 100vw;
    overflow: scroll;
    padding: 0;
    margin: 0;

    & > span {
      display: inline-flex;

      & > .testimonial {
        display: flex;
        flex-direction: column;
        align-items: center;

        width: calc(100vw - (2 * var(--tml-padding)));
        padding: var(--tml-padding) 0;
        margin: 0 var(--tml-padding);
        border: unset;
        background-color: unset;

        &:first-child {
          margin-left: var(--tml-padding);
        }

        &:last-child {
          margin-right: var(--tml-padding);
        }

        @media screen and (min-width: 1000px) {
          width: 700px;
          padding: 50px 100px;
          margin: 0;
        }

        & > h3 {
          font-family: var(--site-font-ibm) !important;
          font-weight: 300;
          text-align: center;
        }

        & > h5 {
          font-size: 17px;
          font-weight: bold;
          margin: 5px 0;
        }

        & > p {
          font-size: 15px;
          color: #949494;
          margin: 5px 0;
        }

        & > img {
          height: 72px;
          width: 72px;
        }
      }
    }
  }
}